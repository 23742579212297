import React, { useEffect } from 'react'
import './Menu.scss'

import menu1 from '../../assets/img/photo_2024-12-19_20-34-13.jpg'
import menu2 from '../../assets/img/photo_2024-12-19_20-34-23.jpg'
import menu3 from '../../assets/slider/IMG_6092.jpeg'
import Meal from '../Meal/Meal'

import pdfMenu from '../../assets/file/web_menu_210x297_compressed.pdf'
import pdfDrinks from '../../assets/file/drinks.pdf'
import pdfBar from '../../assets/file/bar.pdf'
import pdfWine from '../../assets/file/wine.pdf'
import MenuMobile from './MenuMobile/MenuMobile'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Menu = () => {
  const openPdfMenu = () => {
    window.open(pdfMenu, '_blank');
  };
  const openPdfDrinks = () => {
    window.open(pdfDrinks, '_blank');
  };
  const openPdfBar = () => {
    window.open(pdfBar, '_blank');
  };
  const openPdfWine = () => {
    window.open(pdfWine, '_blank');
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <div className='menu-desktop'>
        <div className='menu-section-1'>
          <div className='menu-section-1-content'>
            <div className='menu-section-1-block'>
              <Meal
                dataAos="zoom-out-right"
                dataAosDuration="500"
                img={menu1}
                title='PAELLA MIXTA'
                desc=''
                price='25 €'
              />
              <Meal
                img={menu2}
                dataAos="zoom-out-right"
                dataAosDuration="600"
                title='CHEESE BAKED LOBSTER'
                desc=''
                price='12,5 € per 100g'
              />
              <Meal
                img={menu3}
                dataAos="zoom-out-right"
                dataAosDuration="700"
                title='SALMON TARTARE '
                desc=''
                price='19'
              />
            </div>
          </div>
        </div>
        <div className='menu-section-2'>
          <button className='menu-section-2-button' onClick={openPdfMenu} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Download menu</button>
          <button className='menu-section-2-button' onClick={openPdfDrinks} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Drinks</button>
          <button className='menu-section-2-button' onClick={openPdfBar} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Bar</button>
          <button className='menu-section-2-button' onClick={openPdfWine} data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500">Wine</button>
        </div>
      </div>

      <MenuMobile />
    </>
  )
}

export default Menu
