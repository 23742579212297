import React from 'react'
import './SliderCard.scss'
import { FaInstagram } from "react-icons/fa";

const SliderCard = ({ img, text, price }) => {
  const openInstagram = () => {
    window.open('https://www.instagram.com/la_medusa_tenerife/', '_blank');
  };

  return (
    <div className='sliderCard'>
      <img className='sliderCard__img' src={img} alt="img" />
      <div className='sliderCard__absolut'>
        <div onClick={() => openInstagram()} className='sliderCard__absolut__left'>
          <FaInstagram className='icon' />
          <p>La medusa</p>
        </div>
        <p>{price} €</p>
      </div>
      <p className='sliderCard__text-block'>{text}</p>
    </div>
  )
}

export default SliderCard
