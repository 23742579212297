import React, { useEffect, useRef } from 'react';
import './ImagesBlock.scss';
import img1 from '../../../../assets/slider/IMG_5630.jpeg';
import img2 from '../../../../assets/slider/IMG_6092.jpeg';
import img3 from '../../../../assets/slider/IMG_6378.jpeg';

const ImagesBlock = () => {
  const scrollYRef = useRef(0);
  const isMobile = window.innerWidth <= 768; 
  const data = [
    { img: img1, text: 'Truffle honey pizza / Pizza con miel trufada' },
    { img: img2, text: 'Salmon tartare / Tartar de salmón' },
    { img: img3, text: '4 varieties of Tacos' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      scrollYRef.current = window.scrollY; 
      requestAnimationFrame(updateImages); 
    };

    const updateImages = () => {
      const images = document.querySelectorAll('.images-block__image');
      images.forEach((img, index) => {
        const xOffset = Math.sin(scrollYRef.current / 100 + index) * (isMobile ? 3 : 10); 
        const yOffset = (scrollYRef.current * 0.1 + index * 5) % (isMobile ? 5 : 20);
        img.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <div className="images-block">
      {data.map((item, index) => (
        <div key={index} style={{ width: '80%', height: '80%', position: 'relative' }}>
          <div className="images-block__container">
            <img
              className="images-block__image"
              src={item.img}
              alt=""
              style={{
                willChange: 'transform', 
              }}
            />
          </div>
          <div className="images-block__text">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default ImagesBlock;
