import React, { useState, useEffect } from 'react';
import './Sliders.scss';
import img1 from '../../../../assets/img/photo_2024-12-19_20-34-23.jpg';
import img2 from '../../../../assets/slider/IMG_6150.jpeg';
import img3 from '../../../../assets/slider/IMG_6181.jpeg';
import img4 from '../../../../assets/slider/IMG_6182.jpeg';
import img5 from '../../../../assets/slider/IMG_6311.jpeg';
import img6 from '../../../../assets/slider/IMG_6331.jpeg';
import img7 from '../../../../assets/slider/IMG_6361.jpeg';
import img8 from '../../../../assets/slider/IMG_6149.jpeg';
import img9 from '../../../../assets/slider/IMG_6566.jpeg';
import SliderCard from './components/SliderCard';

const Sliders = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [blocks, setBlocks] = useState([]);

  const items = [
    { img: img1, text: "Cheese baked lobster", price: "12,5 per 100 g" },
    { img: img2, text: "Lobster pasta", price: "29 / 21" },
    { img: img3, text: "Chuleton 1kg / Chuletón 1kg", price: "19,50 P.P" },
    { img: img4, text: "Black angus ribeye steak/ Lomo alto Black Angus", price: "36" },
    { img: img5, text: "Mixta/ Mixta", price: "25 P.P." },
    { img: img6, text: "Syrniki with mascarpone cream/ Syrniky con crema de mascarpone", price: "11" },
    { img: img7, text: "Big seafood plate/ Parrillada de mariscos", price: "32 P.P" },
    { img: img8, text: "Pork ribs BBQ / Costillas decerdo en salsa Barbacoa", price: "21" },
    { img: img9, text: "Angry bird (spicy chicken buffalo style burger) / Hamburguesa con pollo picante", price: "16" }
  ];

  const groupItems = (items, itemsPerGroup) => {
    const grouped = [];
    for (let i = 0; i < items.length; i += itemsPerGroup) {
      grouped.push(items.slice(i, i + itemsPerGroup));
    }
    return grouped;
  };

  useEffect(() => {
    const updateBlocks = () => {
      const screenWidth = window.innerWidth;
      let itemsPerGroup = 3;

      if (screenWidth <= 580) {
        itemsPerGroup = 1;
      } else if (screenWidth <= 900) {
        itemsPerGroup = 2;
      }

      setBlocks(groupItems(items, itemsPerGroup));
    };

    updateBlocks();
    window.addEventListener('resize', updateBlocks);

    return () => window.removeEventListener('resize', updateBlocks);
  }, []);



  const handleNext = () => {
    if (currentIndex < blocks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="slider-container">
      <button className="arrow left" onClick={handlePrev}>
        ◀
      </button>

      <div className="slider">
        <div
          className="slider-track"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {blocks.map((group, index) => (
            <div className="slider-group" key={index}>
              {group.map((block, idx) => (
                <SliderCard
                  key={idx}
                  text={block.text}
                  price={block.price}
                  img={block.img}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      <button className="arrow right" onClick={handleNext}>
        ▶
      </button>
    </div>
  );
};

export default Sliders;
