import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './DateOfReservation.scss';

const DateOfReservation = ({ date, setDate }) => {
  const disableTile = ({ date }) => {
    return date.getDate() === 25 && date.getMonth() === 11;
  };

  return (
    <div className='date'>
      <div className='calendar-container'>
        <Calendar
          onChange={setDate}
          value={date}
          calendarStartDay={1}
          locale="en"
          tileDisabled={disableTile}
        />
      </div>
      <div className='date__text'>
        <span className='date__text'>Selected Date:</span>{' '}
        {date?.toDateString()}
      </div>
    </div>
  );
};

export default DateOfReservation;
